import * as React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { motion, AnimatePresence } from "framer-motion";

import ProjectButton from "../components/ProjectButton";
import ImageBgLincoln from "../assets/projectsImages/lincoln.jpg";
import ImageBgAkar from "../assets/projectsImages/akar.jpg";
import ImageBgHiro from "../assets/projectsImages/hiro.jpg";
import ImageBgLandd from "../assets/projectsImages/landd.jpg";
import ImageBgSudoku from "../assets/projectsImages/sudoku.jpg";
import ImageBgNectar from "../assets/projectsImages/nectar.jpg";

import ProjectTag from "../components/ProjectTag";

import HeaderBigger from "../components/HeaderBigger";

const ProjectList = () => {
  return (
    <AnimatePresence>
      <div className="min-h-[260px] bg-[#F5F8F6] mx-auto py-12 md:py-24 px-6 bg-none bg-no-repeat bg-bottom bg-contain">
        <h1 className="text-4xl sm:text-5xl text-gray-700 font-bold mt-4 text-center">
          A couple of my projects.
        </h1>
        <div className="max-w-screen-xl mx-auto md:px-10 pt-10">
          <motion.h1
            className="text-xl md:text-3xl text-center text-gray-500 px-4 md:px-36 pb-20 md:pb-10"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            exit={{ opacity: 0 }}
          >
            Take a look at the overview of my work from recent years.
          </motion.h1>
          <div className="flex flex-col gap-8 py-0 md:gap-12 md:py-12">
            <Link to={`/blood_testing/`}>
              <ProjectButton src={ImageBgNectar}>
                <ProjectTag>Design & Detail</ProjectTag>
                <HeaderBigger>
                  Building a blood testing device for an Emergency Room
                  environment
                </HeaderBigger>
              </ProjectButton>
            </Link>
            <Link to={`/assistant/`}>
              <ProjectButton src={ImageBgLincoln}>
                <ProjectTag>Design & Guide</ProjectTag>
                <HeaderBigger>
                  Crafting the Digital Assistant Experience for highly demanding
                  car owners
                </HeaderBigger>
              </ProjectButton>
            </Link>
            <Link to={`/stem/`}>
              <ProjectButton src={ImageBgHiro}>
                <ProjectTag>Design & Explore</ProjectTag>
                <HeaderBigger>
                  Designing a development program for STEM Teachers in the
                  Philippines
                </HeaderBigger>
              </ProjectButton>
            </Link>
            <Link to={`/sudoku/`}>
              <ProjectButton src={ImageBgSudoku}>
                <ProjectTag>Design & Have Fun</ProjectTag>
                <HeaderBigger>
                  Developing an iOS sudoku game with more playfulness
                </HeaderBigger>
              </ProjectButton>
            </Link>
            <Link to={`/development/`}>
              <ProjectButton src={ImageBgLandd}>
                <ProjectTag>Summary of</ProjectTag>
                <HeaderBigger>
                  My involvement in Learning & Development at IDEO
                </HeaderBigger>
              </ProjectButton>
            </Link>
            <Link to={`/safety/`}>
              <ProjectButton src={ImageBgAkar}>
                <ProjectTag>Research & Define</ProjectTag>
                <HeaderBigger>
                  Bringing human lens to increase safety at palm oil plantations
                </HeaderBigger>
              </ProjectButton>
            </Link>
          </div>
        </div>
      </div>
    </AnimatePresence>
  );
};

export default ProjectList;
