import * as React from "react";
import { HashLink as Link } from "react-router-hash-link";

import HeroImage from "../components/HeroImage";
import Text from "../components/Text";
import TextSmall from "../components/TextSmall";

import Header from "../components/Header";
import HeaderSmall from "../components/HeaderSmall";
import HeaderBig from "../components/HeaderBig";

import Image from "../components/Image";
import Gif from "../components/Gif";
import ProjectButtonSmall from "../components/ProjectButtonSmall";
import ProjectHeadline from "../components/ProjectHeadline";
import ProjectTagSmall from "../components/ProjectTagSmall";

import ParagraphSection from "../components/ParagraphSection";
import LightBgSection from "../components/LightBgSection";
import ProjectFooter from "../components/ProjectFooter";
import ThreeColumns from "../components/ThreeColumns";

import ImageBgLandd from "../assets/projectsImages/landd.jpg";
import ImageBgAkar from "../assets/projectsImages/akar.jpg";

import Imgs from "../assets/sudoku/Imgs";
// PROJECT RELATED IMAGES

const ProjectSudoku = ({ imgHero }) => {
  return (
    <div id="#top" className="">
      {/* HERO IMAGE */}
      <HeroImage src={imgHero} />
      <div className="relative -top-60 md:-top-[20em]">
        {/* CONTENT STARTS HERE */}
        <div className="max-w-screen-lg mx-auto pt-8">
          {/* HEADER + PARAGRAPH */}
          <ProjectHeadline
            subtitle="Design & Have Fun"
            title="Developing an iOS sudoku game with more playfulness"
          />
          <ParagraphSection>
            <Header>Project Overview</Header>
            <Text>
              Engaging in mini-side projects is my go-to method to learn new
              skills and broaden my design toolkit. Side projects allow me to
              stay motivated at the final goal while solving specific problems
              that I could face in my future commercial work.
              <br />
              <br /> One such endeavor led to the creation of Sudoku Round, a
              SwiftUI-based app now nearing 6k downloads on the App Store.
            </Text>
            <div className="text-3xl py-12 ">
              <a
                href="https://apps.apple.com/us/app/sudoku-round/id1528647824"
                className="text-purple-600 underline"
                target="_blank"
                rel="noreferrer"
              >
                Try Sudoku Round yourself!
              </a>
            </div>
          </ParagraphSection>
          {/* HMW */}
          <div className="bg-purple-100 text-purple-600">
            <LightBgSection>
              <h3 className="text-2xl md:text-3xl ">How Might We</h3>
              <h3 className="text-4xl md:text-7xl font-bold ">
                make playing digital sudoku more enjoyable?
              </h3>
            </LightBgSection>
          </div>
          {/* TEAM */}
          <ParagraphSection>
            <Header>But first… Let's rewind to 2016</Header>
            <Text>
              Dissatisfied with the clunky and overwhelming sudoku games on my
              phone, I embarked on a redesign challenge. The solution was simple
              yet innovative - a user long-tap revealing a ring of numbers, with
              a finger swipe to highlight and release to confirm. A playful dot
              follows the finger, adding a fun touch to the interaction.
            </Text>
          </ParagraphSection>
          <Gif src={Imgs.Img01} desc="Initial prototype from 2016" />
          <ParagraphSection>
            <Text>
              You could read more about{" "}
              <a
                href="https://medium.com/framer-prototyping/sudoku-interface-in-framer-js-a28d5e9e7b0e"
                className="underline text-purple-600"
                target="_blank"
                rel="noreferrer"
              >
                my ideation and coding process in the article
              </a>
              , kindly published by an official Framer channel which is still a
              big deal for me.
            </Text>
          </ParagraphSection>
          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>Resurrecting the project during the 2020's lockdown</Header>
            <Text>
              Amid the pandemic's upheavals, I sought a distraction and found it
              in a micro-interaction with the potential to evolve into a
              full-fledged product. To tackle this challenge, I committed to
              learning SwiftUI and enhancing my front-end skills. The existing
              2016 code was far from ideal, functioning only on desktops with
              limitations in selection and animations.
            </Text>
          </ParagraphSection>
          <Image
            src={Imgs.Img02}
            placeholder={Imgs.Img02_placeholder}
            desc="The underhood of the main interaction"
          />
          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>Adapting boards and adding game logic</Header>
            <Text>
              Utilizing{" "}
              <a
                href="https://github.com/JoeKarlsson/python-sudoku-generator-solver"
                className="underline text-purple-600"
                target="_blank"
                rel="noreferrer"
              >
                Joe Karlsson's board generator
              </a>{" "}
              and my modest Python proficiency, I tailored his output to suit
              the Sudoku Round. Adding the logic was straightforward, focused on
              detecting the same numbers in a row, a column, and a square. A
              satisfying animation is triggered upon a player successfully
              filling in the last number.
            </Text>
          </ParagraphSection>
          <Gif src={Imgs.Img03} desc="Testing the logic of errors" />
          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>Testing and iterating on feedback</Header>
            <Text>
              Following Apple's initial approval, I've beta-tested the app with
              my friends. The app, although stable and without crashes, revealed
              a glitch on older iPhones regarding game state retention. Feedback
              from users highlighted the demand for a note feature to capture
              potential numbers inside squares.
            </Text>
          </ParagraphSection>
          <Image
            src={Imgs.Img04}
            placeholder={Imgs.Img04_placeholder}
            desc="List of suggestion and feedback for testers"
          />

          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>Developing notes</Header>
            <Text>
              After the launch, I took a two-month break before implementing a
              highly requested feature. Notes help solve many complex boards as
              they allow players to save potential numbers and provide an
              overview without memorizing every option. <br />
              <br />
              While the note interface was straightforward, I had to put my
              energy into rebuilding the entire history management to ensure
              seamless tracking of notes even when players undid recent inputs.
            </Text>
          </ParagraphSection>
          <Gif src={Imgs.Img05} />

          {/* PARAGRAPH & IMAGE */}
          <ParagraphSection>
            <Header>The current state</Header>
            <Text>
              Sudoku Round has achieved 5.8k downloads, primarily from China.
              Despite experimentation with pricing and advertising, achieving
              self-sustainability proved challenging amidst stiff competition
              from products with larger teams. However, my source of pride is
              the app's high monthly usage, with users engaging once every four
              days.
            </Text>
          </ParagraphSection>
          <Image
            src={Imgs.Img06}
            placeholder={Imgs.Img06_placeholder}
            desc="Current state of Sudoku Round"
          />
          <Image
            src={Imgs.Img07}
            placeholder={Imgs.Img07_placeholder}
            desc="Main features"
          />
          {/* REFLECTIONS*/}
          <div className="bg-purple-100 text-purple-600">
            <LightBgSection>
              <HeaderBig>My Learnings & Reflections</HeaderBig>
              <ThreeColumns>
                <div className="pt-10 md:pt-8">
                  <HeaderSmall>
                    A launch is much better than no launch
                  </HeaderSmall>
                  <TextSmall>
                    While polishing the app, I realized that I was delaying the
                    launch to make everything perfect and add every feature that
                    I initially planned. I had to prioritize launching over
                    adding non-essential features. This included letting go of a
                    step-by-step onboarding, allowing me to channel more energy
                    into marketing materials.
                  </TextSmall>
                </div>
                <div className="pt-10 md:pt-8">
                  <HeaderSmall>Marketing is as important as design</HeaderSmall>
                  <TextSmall>
                    It surprised me how much work it takes to finalize marketing
                    materials. Moreover, I discovered the critical importance of
                    identifying and leveraging social media channels where users
                    are most active. While posting on my social media was
                    rewarding, the real breakthrough in traction occurred after
                    I shared the Sudoku Round on Product Hunt.
                  </TextSmall>
                </div>
                <div className="pt-10 md:pt-8">
                  <HeaderSmall>Appreciating independent developers</HeaderSmall>
                  <TextSmall>
                    Through this journey, I gained a deeper appreciation for
                    solo developers launching products. Managing the entire
                    creative and development process alone involves significant
                    work in design, coding, marketing, and promotion. While it's
                    fulfilling to own the entire process, the responsibility can
                    potentially impact the overall quality of the work and
                    distract from essential priorities.
                  </TextSmall>
                </div>
              </ThreeColumns>
            </LightBgSection>
          </div>
        </div>
      </div>

      {/* PROJECT FOOTER */}
      <ProjectFooter>
        <Link to={`/development/`}>
          <ProjectButtonSmall src={ImageBgLandd}>
            <ProjectTagSmall>Summary of</ProjectTagSmall>
            <HeaderBig>
              My involvement in Learning & Development at IDEO
            </HeaderBig>
          </ProjectButtonSmall>
        </Link>
        <Link to={`/safety/`}>
          <ProjectButtonSmall src={ImageBgAkar}>
            <ProjectTagSmall>Research & Define</ProjectTagSmall>
            <HeaderBig>
              Bringing human lens to increase safety at palm oil plantations
            </HeaderBig>
          </ProjectButtonSmall>
        </Link>
      </ProjectFooter>
    </div>
  );
};

export default ProjectSudoku;
